<template>
      <b-button @click="navigateToPDF" variant="primary">Download PDF</b-button>

  <div>
    <b-card v-if="selectedTemplate" class="template-card">
      <div class="watermark-background"></div>
      <div class="qr-div"><img :src="getQRCodeDataUrl(selectedTemplate)" alt="QR Code" class="qr-code-img" /></div>
      <div class="container">
        <div class="logo">
          <img
            :src="getFullImageUrl(selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo.data.attributes.url)"
            class="tclogo">
        </div>
        <br>
        <div>
          <h1 style="text-align: center;">{{ selectedTemplate.attributes.sk_templates.data.attributes.tour_name }}</h1>
          <h1 style="text-align: center;">Welcome to {{ selectedTemplate.attributes.sk_templates.data.attributes.city
            }}!!!</h1>
        </div>
        <div class="field-box1">
          <h6>We hope that today will be an experience that you will never forget. In order to make it a positive one,
            we
            would like to provide you some useful information that gives you necessary details as well as some emergency
            information. Enjoy your day!!! </h6>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card-text">
              <!-- Drop-off Location -->
              <div class="field-box">
                <span class="field-label">Drop-off Location:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.drop_off_location.data.attributes.name }}
                  ({{ selectedTemplate.attributes.drop_off_location.data.attributes.address }})
                </span>
              </div>
              <!-- Pick-up Location -->
              <div class="field-box">
                <span class="field-label">{{ selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train' ?
                  'Meeting Point' : 'Pick-up Location' }}:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.pick_up_location.data.attributes.name }}
                  ({{ selectedTemplate.attributes.pick_up_location.data.attributes.address }})
                </span>
              </div>

              <!-- Euro exchange shop -->
              <div class="field-box">
                <span class="field-label">Euro Exchange Shop:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.euro_exchange.data.attributes.name }}
                  ({{ selectedTemplate.attributes.euro_exchange.data.attributes.address }})
                </span>
              </div>
              <!-- Nearest Public Train Station -->
              <div class="field-box">
                <span class="field-label">Nearest Public Train Station:</span>
                <span class="field-value">{{ selectedTemplate.attributes.underground_station.data.attributes.name
                  }}</span>
              </div>
              <!-- Departure Train Station (Only for types other than 'Bus') -->
              <div class="field-box" v-if="selectedTemplate.attributes.sk_templates.data.attributes.type !== 'Bus'">
                <span class="field-label">Departure Train Station:</span>
                <span class="field-value">{{ selectedTemplate.attributes.train_station.data.attributes.name }}</span>
              </div>

              <!-- Meeting Time -->
              <div class="field-box2">
                <span class="field-label">Meeting Time:</span>
                <span class="field-value">{{ formatTime(selectedTemplate.attributes.meeting_time) }}</span>
              </div>
              <!-- Bus Departure Time (For 'Train and Bus' type) -->
              <div class="field-box2"
                v-if="selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train and Bus'">
                <span class="field-label">Bus Departure Time:</span>
                <span class="field-value">{{ formatTime(selectedTemplate.attributes.busDepartureTime) }}</span>
              </div>
              <!-- Bus Departure Time (Only for 'Bus' type) -->
              <div class="field-box2" v-if="selectedTemplate.attributes.sk_templates.data.attributes.type === 'Bus'">
                <span class="field-label">Bus Departure Time:</span>
                <span class="field-value">{{ formatTime(selectedTemplate.attributes.busDepartureTime) }}</span>
              </div>

              <!-- Train Departure Time (For other types) -->
              <div class="field-box2" v-else>
                <span class="field-label">Train Departure Time:</span>
                <span class="field-value">{{ formatTime(selectedTemplate.attributes.train_departuretime) }}</span>
              </div>

              <div class="field-box1">
                <h6>In case of emergencies: </h6>
              </div>
              <div class="field-box">
                <span class="field-label">Emergency Contact for Police, Ambulance:</span>
                <span class="field-value">112</span>
              </div>
              <div class="field-box">
                <span class="field-label">American Embassy Address:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.sk_templates.data.attributes.address_US }}<br>
                  <span>
                    <span class="field-label">American Embassy Contact:</span>
                    <a :href="'tel:' + selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUS">
                      {{ selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUS }}
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-text">
              <!-- Emergency Contact USconsulate -->
          
              <div class="field-box">
                <span class="field-label">American Consulate Address:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.sk_templates.data.attributes.address_USconsulate}}<br>
                  <span>
                    <span class="field-label">American Consulate Contact:</span>
                    <a :href="'tel:' + selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUSconsulate">
                      {{ selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUSconsulate }}
                    </a>
                  </span>
                </span>
                
              </div>
              <!-- Emergency Contact Uk -->
              <div class="field-box">
                <span class="field-label">Bristish Embassy Address:</span>
                <span class="field-value">
                  {{ selectedTemplate.attributes.sk_templates.data.attributes.address_UK }}<br>
                  <span>
                    <span class="field-label">British Embassy Contact:</span>
                    <a :href="'tel:' + selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUK">
                      {{ selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUK }}
                    </a>
                  </span>

                </span>
              </div>
              <div class="field-box">
                <span class="field-label"></span>
                <span class="field-value">If you would like to make a call to the United States of America, the
                  following
                  # can be dialed to reach an American operator (for collect or card calls): <a href="tel:">0800 225
                    5288</a></span>
              </div>
              <div class="field-box1">
                <h6>The {{ selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.name }}
                  Cruise Line agent in {{
                    selectedTemplate.attributes.sk_templates.data.attributes.city }} is Time Change GmbH. In case of an
                  emergency, please contact: </h6>
              </div>
              <!-- Emergency Phone Number -->
              <div class="field-box">
                <span class="field-label">Emergency Contact Tour Operator:</span>
                <span class="field-value">
                  <a :href="'tel:' + selectedTemplate.attributes.emergency_tc">
                    {{ selectedTemplate.attributes.emergency_tc }}
                  </a>
                </span>

              </div>
              <div class="field-box">
                <span class="field-label">Time Change GmbH Address:</span>
                <span class="field-value">{{
                  selectedTemplate.attributes.sk_templates.data.attributes.address_tc }}</span>
              </div>
            </div>

            <div class="field-box1">
              <h6>If you should get lost, or if you have any problems and don't know what to do, etc. please click the
                button below and show the following phrase to someone (A policeman would be best). It basically asks the
                person to help you to get to the departure point at the right time. </h6>
              <b-button v-b-toggle.collapse-1 variant="primary">I Get Lost</b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <div v-if="selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train and Bus'">
                  <b-card class="abc">

                    HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER
                    VERLAUFEN.
                    UM <strong>{{ formatTime(selectedTemplate.attributes.meeting_time) }} UHR</strong> 
                    SOLLTE ICH SPÄTESTENS <strong>{{
                      selectedTemplate.attributes.pick_up_location.data.attributes.name.toUpperCase() }}</strong>
                    SEIN, DA ICH DORT MEINEN TRANSFERBUS HABE, DER MICH ANSCHLIEßEND ZU MEINEM ZUG AM <strong>{{
                      selectedTemplate.attributes.train_station.data.attributes.name.toUpperCase() }}</strong>
                    (ZUGABFAHRT UM <strong>{{ formatTime(selectedTemplate.attributes.train_departuretime) }}
                      UHR</strong>).
                    ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.
                  </b-card>
                </div>

                <div v-else-if="selectedTemplate.attributes.sk_templates.data.attributes.type === 'Bus'">
                  <b-card class="abc">
                    HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER
                    VERLAUFEN.
                    UM <strong>{{ formatTime(selectedTemplate.attributes.meeting_time) }} UHR</strong> SOLLTE ICH
                    SPÄTESTENS
                    AM <strong>{{ selectedTemplate.attributes.pick_up_location.data.attributes.name.toUpperCase()
                      }}</strong>
                    SEIN, DA ICH DORT MEINEN BUS NACH <strong>{{
                      selectedTemplate.attributes.sk_templates.data.attributes.end_city.toUpperCase() }}</strong>
                    RECHTZEITIG ERREICHEN MUSS. ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.

                  </b-card>
                </div>

                <div v-else-if="selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train'">
                  <b-card class="abc">
                    HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER
                    VERLAUFEN.
                    UM <strong>{{ formatTime(selectedTemplate.attributes.meeting_time) }} UHR</strong>
                    SOLLTE ICH SPÄTESTENS AM <strong>{{
                      selectedTemplate.attributes.train_station.data.attributes.name.toUpperCase() }}</strong>
                    SEIN, UM DORT MEINEN ZUG UM <strong>{{ formatTime(selectedTemplate.attributes.train_departuretime)
                      }} UHR</strong>
                    ZU ERREICHEN. ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.

                  </b-card>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <div v-else>
      <b-spinner></b-spinner>
    </div>
    <div class="row">
      <!-- Map Column -->
      <div class="col-md-8">
        <b-card style="max-width: 100rem;" class="mb-2 mt-3">
          <div id="locationMap" style="min-height: 750px; max-width: 100%; "></div>
        </b-card>
      </div>

      <!-- Restaurant Cards Column -->
      <div class="col-md-4">
        <div v-if="selectedLocation" class="container-fluid">
          <template v-for="restaurant in selectedLocation.attributes.restaurants.data">
            <b-card style="max-width: 100%; background-color:#F0F0F0; font-family:Verdana;" class="mb-3">
              <b-card-text>
                <strong>{{ restaurant.attributes.name }}</strong>
              </b-card-text>
              <b-card-text>
                Opening Hours: <strong>{{ restaurant.attributes.opening_hours }}</strong>
              </b-card-text>
              <b-card-text>
                Address: <strong>{{ restaurant.attributes.address }}</strong>
              </b-card-text>
              <b-button :href="restaurant.attributes.website" variant="primary">To Website</b-button>
              <b-button @click="openNavigationModal(restaurant)" variant="success"
                class="btn-spacing">Navigate</b-button>
            </b-card>
          </template>
          <b-modal id="navigationModal" title="Navigation Options">
            <p>Choose your navigation app:</p>
            <ul>
              <li><a :href="googleMapsUrl" target="_blank">Open in Google Maps</a></li>
              <li><a :href="appleMapsUrl" target="_blank">Open in Apple Maps</a></li>
            </ul>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from 'google-maps';
import QRCode from 'qrcode-generator';
import config from '@lib/frontend/config';

export default {
  data() {
    return {
      selectedTemplate: null,
      locationMap: null,
      selectedLocation: null,
      restaurants: [],
    };
  },
  async mounted() {
    const templateId = this.$route.params.id;
    const result = await this.$xhr.get(`/api/sk-items/${templateId}?populate[sk_templates][populate]=cruise_line.logo&populate[drop_off_location][populate]=*&populate[pick_up_location][populate]=*&populate[underground_station][populate]=*&populate[train_station][populate]=*&populate[euro_exchange][populate]=*`);
    if (result.data.data) {
      this.selectedTemplate = result.data.data;
      const pickUpLocationId = this.selectedTemplate.attributes.pick_up_location.data.id;

      const rest = `/api/sk-locations?filters[id]=${pickUpLocationId}&populate=restaurants`;
      const locationResult = await this.$xhr.get(rest);

      if (locationResult.data.data && locationResult.data.data.length > 0) {
        this.selectedLocation = locationResult.data.data[0];

        if (this.selectedLocation.attributes.restaurants && this.selectedLocation.attributes.restaurants.data.length > 0) {
          this.restaurants = this.selectedLocation.attributes.restaurants.data;
        }
      }
    } else {
      this.selectedTemplate = null;
    }
    await this.generateLocationMap();
  },
  methods: {
    openNavigationModal(restaurant) {
      if (restaurant.attributes.latitude && restaurant.attributes.longitude) {
        const restaurantLatLng = new google.maps.LatLng(
          restaurant.attributes.latitude,
          restaurant.attributes.longitude
        );
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${restaurantLatLng.lat()},${restaurantLatLng.lng()}&travelmode=walking&dir_action=navigate`;
        const appleMapsUrl = `http://maps.apple.com/?daddr=${restaurantLatLng.lat()},${restaurantLatLng.lng()}&dirflg=w`;

        // Set the URLs in data properties for the modal to use
        this.googleMapsUrl = googleMapsUrl;
        this.appleMapsUrl = appleMapsUrl;

        // Open the navigation modal
        this.$bvModal.show('navigationModal');
      }
    },
    getFullImageUrl(partialUrl) {
      return config.buildUrl('api', partialUrl)
    },

    getQRCodeDataUrl(template) {
      if (template) {
        const qrCodeDataUrl = this.generateQRCode(config.buildUrl('frontend', `/#/clientpage/${template.id}`));
        return qrCodeDataUrl;
      }
      return '';
    },
    generateQRCode(url) {
      const qr = QRCode(0, 'H'); // Highest error correction level (H)
      qr.addData(url);
      qr.make();

      // Increase the size of the QR code
      const qrCodeDataUrl = qr.createDataURL(10); // Adjust the size (10 means larger)

      return qrCodeDataUrl;
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':').map(Number);
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    },
    async generateLocationMap() {
      if (
        this.selectedTemplate &&
        this.selectedTemplate.attributes.drop_off_location &&
        this.selectedTemplate.attributes.pick_up_location &&
        this.selectedTemplate.attributes.underground_station &&
        this.selectedTemplate.attributes.train_station
      ) {
        const options = {};
        const loader = new Loader('AIzaSyBefUlhmRp62R6Zs6iNYYf38DRLnIffbmM', options);

        try {
          const google = await loader.load();

          let map;

          if (this.locationMap) {
            this.locationMap.setMap(null);
          }

          map = new google.maps.Map(document.getElementById('locationMap'), {
            center: { lat: 0, lng: 0 },
            zoom: 0,
          });

          const bounds = new google.maps.LatLngBounds();

          function addLocationMarker(location, title, iconUrl) {
            if (location.attributes.latitude && location.attributes.longitude) {
              const markerLatLng = new google.maps.LatLng(
                location.attributes.latitude,
                location.attributes.longitude
              );

              let markerIcon;

              if (iconUrl) {
                markerIcon = {
                  url: iconUrl,
                  scaledSize: new google.maps.Size(32, 32),
                };
              }

              const marker = new google.maps.Marker({
                position: markerLatLng,
                map: map,
                icon: markerIcon,
                title: title,
              });

              bounds.extend(markerLatLng);

              const infoWindowContent =
                `<div>
               <h6>${title}</h6>
               <p><a href="https://www.google.com/maps/dir/?api=1&destination=${markerLatLng.lat()},${markerLatLng.lng()}&travelmode=walking&dir_action=navigate" target="_blank">Navigate with Google Maps</a>
                <br><br><a href="http://maps.apple.com/?daddr=${markerLatLng.lat()},${markerLatLng.lng()}&dirflg=w" target="_blank">Navigate with Apple Maps</a>
               </p>
              </div>`;
              const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
              });
              google.maps.event.addListener(marker, 'click', () => {
                infoWindow.open(map, marker);
              });
            }
          }

          addLocationMarker(this.selectedTemplate.attributes.drop_off_location.data, 'Drop-off Location', null);
          addLocationMarker(this.selectedTemplate.attributes.pick_up_location.data,
            this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train' ? 'Meeting Point' : 'Pick-up Location', null);
          addLocationMarker(this.selectedTemplate.attributes.underground_station.data, 'Nearest Public Train Station', null);

          // Check the type of the template before adding the train station marker
          if (
            this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train' ||
            this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train and Bus'
          ) {
            addLocationMarker(this.selectedTemplate.attributes.train_station.data, 'Departure Train Station', null);
          }

          addLocationMarker(this.selectedTemplate.attributes.euro_exchange.data, 'Euro Exchange Shop', null);

          if (
            this.selectedLocation &&
            this.selectedLocation.attributes.restaurants &&
            this.selectedLocation.attributes.restaurants.data.length > 0
          ) {
            this.selectedLocation.attributes.restaurants.data.forEach((restaurant) => {
              const restaurantTitle = restaurant.attributes.name;
              const restaurantMarkerIconUrl = 'https://survival.time-change.tech/uploads/restaurant_679d8944bf.png';
              addLocationMarker(restaurant, restaurantTitle, restaurantMarkerIconUrl);
            });
          }

          map.fitBounds(bounds);

          this.locationMap = map;
        } catch (error) {
          console.error("Error loading Google Maps:", error);
        }
      }
    },
    navigateToPDF() {
      const clientId = this.$route.params.id; // Assuming you have a parameter 'id' in the route
      this.$router.push({ name: 'clientpdf', params: { id: clientId } });
    }
  },
};
</script>

<style scoped>
.field-box1 {
  background-color: #C0C0C0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.field-box2 {
  background-color: #E69331;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.abc {
  background-color: #E69331;
}

.btn-spacing {
  margin-left: 10px;
}

.template-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 100px;
  margin: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.watermark-background {
  content: "";
  background-image: url('@lib/frontend/assets/new.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.05;
  pointer-events: none;
  transform: rotate(0deg);

}

.qr-div {
  width: fit-content;
  height: fit-content;
}

.qr-code-img {
  max-width: 20%;
  height: auto;
}



.container {
  padding: 20px;
  max-width: 100%; 
}

.container-fluid {
  padding: 0px;
  margin-top: 1rem;
}

.field-box {
  background-color: #F0F0F0;
  padding: 10px;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.field-label {
  color: #000;
  font-family: Verdana;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.field-value {
  font-family: Roboto;
  font-size: 18px;
  text-align: center;
}

.logo {
  margin-top: -20px;
  text-align: center;
}

.tclogo {
  max-width: 300px;
}


/* Add responsive styles for mobile */
@media (max-width: 767px) {
  .template-card {
    margin: 10px 0;
    padding: 15px;
  }

  .field-label {
    font-size: 18px;
  }

  .field-value {
    font-size: 16px;
  }

  .tclogo {
    max-width: 100%;
  }

  .description-box {
    font-size: 16px;
    padding: 15px;
  }

  .container-fluid {
    max-width: 100%;
  }

  #locationMap {
    min-height: 300px;
    margin-top: 20px;
  }

}
</style>
